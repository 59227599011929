<template>
  <div>
    <div>
      <layout-breadcrumb :list="[{'name':'测评管理'}]"></layout-breadcrumb>
    </div>
    <div>
      <Card>
        <div slot="title" style="height: 28px;line-height: 28px;display: flex;justify-content: space-between">
          <div>
            <Icon type="md-list-box" size="20"></Icon>
            测评列表
          </div>
          <div>
            <pushwork @ok="queryHomeworkCycle"></pushwork>
          </div>
        </div>
        <div>
          <div style="display: flex;justify-content: space-between">
            <div>
              <Form ref="formInline1" :model="queryForm1" :inline="true" :label-width="70">
                <FormItem label="作业名称">
                  <Input type="text" v-model="queryForm1.name" placeholder="请输入作业名称" clearable/>
                </FormItem>
                <FormItem label="选择时间">
                  <DatePicker v-model="queryForm1.time" style="width: 320px" type="datetimerange" placeholder="请选择扫描起止时间"
                  ></DatePicker>
                </FormItem>
                <Button type="primary" style="margin-bottom: 20px;" @click="queryHomeworkCycle" icon="ios-search">查询
                </Button>
              </Form>
            </div>
          </div>
          <div>
            <div>
              <Table ref="table" :loading="tableLoading1" :columns="columns1" :data="pageList1" border>
                <template slot-scope="{ row }" slot="action">
                  <Button type="primary" size="small" @click="catDetail1(row)">查看</Button>
                </template>
              </Table>
            </div>
            <div style="display: flex;justify-content: center;margin-top: 20px">
              <Page :total="pageData1.total" :page-size="pageData1.pageSize" @on-change="changePageNo1"/>
            </div>
          </div>
        </div>
<!--        <Tabs value="name1" style="min-height: 500px">-->
<!--          <TabPane label="在线作业" name="name1">-->
<!--            <div>-->
<!--              <div style="display: flex;justify-content: space-between">-->
<!--                <div>-->
<!--                  <Form ref="formInline1" :model="queryForm1" :inline="true" :label-width="70">-->
<!--                    <FormItem label="作业名称">-->
<!--                      <Input type="text" v-model="queryForm1.name" placeholder="请输入作业名称" clearable/>-->
<!--                    </FormItem>-->
<!--                    <FormItem label="选择时间">-->
<!--                      <DatePicker v-model="queryForm1.time" style="width: 320px" type="datetimerange" placeholder="请选择扫描起止时间"-->
<!--                      ></DatePicker>-->
<!--                    </FormItem>-->
<!--                    <Button type="primary" style="margin-bottom: 20px;" @click="queryHomeworkCycle" icon="ios-search">查询-->
<!--                    </Button>-->
<!--                  </Form>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div>-->
<!--                <div>-->
<!--                  <Table ref="table" :loading="tableLoading1" :columns="columns1" :data="pageList1" border>-->
<!--                    <template slot-scope="{ row }" slot="action">-->
<!--                      <Button type="primary" size="small" @click="catDetail1(row)">查看</Button>-->
<!--                    </template>-->
<!--                  </Table>-->
<!--                </div>-->
<!--                <div style="display: flex;justify-content: center;margin-top: 20px">-->
<!--                  <Page :total="pageData1.total" :page-size="pageData1.pageSize" @on-change="changePageNo1"/>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </TabPane>-->
<!--          <TabPane label="课堂作业（pdf）" name="name2">-->
<!--            <div>-->
<!--              <div style="display: flex;justify-content: space-between">-->
<!--                <div>-->
<!--                  <Form ref="formInline" :model="queryForm" :inline="true" :label-width="70">-->
<!--                    <FormItem label="选择班级" v-if="commonClass.name == ''">-->
<!--                      <Select v-model="queryForm.classId" style="width:200px">-->
<!--                        <Option v-for="item in classList" :value="item.classId" :key="item.classId">-->
<!--                          {{ item.className + ' (' + item.enterYear + ')届' }}-->
<!--                        </Option>-->
<!--                      </Select>-->
<!--                    </FormItem>-->
<!--                    <FormItem label="选择时间">-->
<!--                      <DatePicker v-model="queryForm.time" style="width: 320px" type="datetimerange" placeholder="请选择扫描起止时间"-->
<!--                      ></DatePicker>-->
<!--                    </FormItem>-->
<!--                    <Button type="primary" style="margin-bottom: 20px;" @click="queryPageList" icon="ios-search">查询-->
<!--                    </Button>-->
<!--                  </Form>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div>-->
<!--                <div>-->
<!--                  <Table ref="table" :loading="tableLoading" :columns="columns" :data="pageList" border>-->
<!--                    <template slot-scope="{ row }" slot="action">-->
<!--                      <Button type="primary" size="small" @click="catDetail(row)">查看</Button>-->
<!--                    </template>-->
<!--                  </Table>-->
<!--                </div>-->
<!--                <div style="display: flex;justify-content: center;margin-top: 20px">-->
<!--                  <Page :total="pageData.total" :page-size="pageData.pageSize" @on-change="changePageNo"/>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </TabPane>-->
<!--        </Tabs>-->

      </Card>
    </div>
  </div>
</template>

<script>
import pushwork from '@/views/modules/pushwork/index';

export default {
  name: "teacher-homeWork",
  components: {pushwork},
  data() {
    return {
      queryForm: {
        classId: '',
        time: []
      },
      queryForm1: {
        name: '',
        time: []
      },
      tableLoading: true,
      tableLoading1: true,
      pageList: [],
      pageList1: [],
      classList: [],
      pageData: {
        pageNo: 1,
        pageSize: 10,
        total: 0
      },
      pageData1: {
        pageNo: 1,
        pageSize: 10,
        total: 0
      },
      columns: [
        {
          title: '作业编号',
          key: 'number'
        },
        {
          title: '作业名称',
          key: 'name'
        },
        {
          title: '作业内容',
          key: 'content'
        },
        {
          title: '班级',
          key: 'className'
        },
        {
          title: '扫描时间',
          key: 'createInDate'
        },
        {
          title: '操作',
          slot: 'action',
          fixed: 'right',
          width: 100,
        }
      ],
      columns1: [
        {
          title: '作业名称',
          key: 'name'
        },
        {
          title: '作业内容',
          key: 'content'
        },
        {
          title: '班级',
          key: 'classNames'
        },
        {
          title: '创建时间',
          key: 'createInDate'
        },
        {
          title: '操作',
          slot: 'action',
          fixed: 'right',
          width: 100,
        }
      ],
      commonClass:{
        id: 0,
        name: ''
      }
    }
  },
  created() {
    this.getInfo()
  },
  mounted() {
    this.queryHomeworkCycle();
    this.queryPageList();
    this.queryClassStudent();
  },
  methods: {
    getInfo() {
      let data = this.$routeQuery()
      if (data.enterYear) {
        data.name = data.enterYear + '届' + data.name
      } else {
        data.name = ''
      }
      this.commonClass = data
    },
    queryClassStudent() {
      this.$http.queryClassStudent().then(res => {
        if (res.status == 1) {
          this.classList = res.data.map(item => {
            return item.classInfo;
          });
        }
      })
    },
    queryPageList() {
      let classId = this.commonClass.id ? this.commonClass.id : this.queryForm.classId
      this.$http.queryHomeworkList(
          this.pageData.pageNo,
          this.pageData.pageSize,
          classId,
          this.queryForm.time[0] ? this.$utils.getTimeStr(this.queryForm.time[0]).substring(0, 19) : '',
          this.queryForm.time[1] ? this.$utils.getTimeStr(this.queryForm.time[1]).substring(0, 19) : '',
      ).then(res => {
        this.tableLoading = false;
        if (res.status == 1) {
          this.pageList = res.data.records;
          this.pageData.total = res.data.total;
        }
      });
    },
    changePageNo(val) {
      this.pageData.pageNo = val;
      this.queryPageList();
    },
    changePageNo1(val) {
      this.pageData1.pageNo = val;
      this.queryHomeworkCycle();
    },
    catDetail(row) {
      let name = row.enterYear + '届' + row.className
      this.$jump('/detail-list', {homeworkNumber: row.number, name: name});
    },
    catDetail1(row) {
      console.log('row ==>', row)
    },
    queryHomeworkCycle() {
      this.$http.queryHomeworkCycle(
          this.pageData1.pageNo,
          this.pageData1.pageSize,
          this.queryForm1.name,
          this.queryForm1.time[0] ? this.$utils.getTimeStr(this.queryForm1.time[0]).substring(0, 19) : '',
          this.queryForm1.time[1] ? this.$utils.getTimeStr(this.queryForm1.time[1]).substring(0, 19) : '',
      ).then(res => {
        this.tableLoading1 = false;
        if (res.status == 1) {
          this.pageList1 = res.data.records.map(item=>{
            item.classNames='';
            for (let ele of item.classInfos) {
              item.classNames += ele.className+' ';
            }
            return item;
          });
          this.pageData1.total = res.data.total;
        }
      });
    }
  }
}
</script>

<style scoped>

</style>