<template>
  <div>
    <div>
      <div>
        <div style="display: flex;justify-content: space-between">
          <div>
            <Button type="primary" @click="showModal" icon="md-send">发布作业</Button>
          </div>
        </div>
      </div>
    </div>
    <Modal draggable reset-drag-position sticky footer-hide width="630"
           v-model="modalStatus"
           title="发布作业"
           @on-cancel="closeModal">
      <div>
        <Form ref="formValidate" :model="formValidate" :rules="ruleValidate" :label-width="80">
          <FormItem label="作业类型" prop="workType">
            <RadioGroup v-model="formValidate.workType">
              <Radio :label="1">在线作业</Radio>
<!--              <Radio :label="2">课堂作业（pdf）</Radio>-->
            </RadioGroup>
          </FormItem>
          <FormItem label="作业名称" prop="name" v-if="formValidate.workType==1">
            <Input type="text" v-model="formValidate.name" placeholder="请输入作业名称"/>
          </FormItem>
          <FormItem label="重复">
            <i-switch size="large" v-model="formValidate.repeatStatus">
              <span slot="open">是</span>
              <span slot="close">否</span>
            </i-switch>
          </FormItem>
          <FormItem label="周期时间" prop="planTime" v-if="formValidate.repeatStatus">
            <DatePicker type="datetimerange" format="yyyy-MM-dd" placeholder="起止时间" @on-change="setPlanTime"
                        style="width: 340px" split-panels></DatePicker>
          </FormItem>
          <FormItem label="重复时间" prop="repeatTime" v-if="formValidate.repeatStatus">
            <CheckboxGroup v-model="formValidate.repeatTime" @on-change="formValidate.repeatTime.sort()">
              <Checkbox v-for="item in repeatTimeList" :label="item.id" :key="item.id">{{ item.name }}</Checkbox>
            </CheckboxGroup>
          </FormItem>
          <Tabs type="card"
                v-if="formValidate.workType==1&&formValidate.repeatStatus&&formValidate.repeatTime.length>0">
            <TabPane :label="repeatTimeList[item-1].name" v-for="(item,index) in formValidate.repeatTime" :key="index">
              <FormItem :label="repeatTimeList[item-1].name+'作业内容'" :prop="'content'+item" :label-width="125"
                        v-if="formValidate.workType==1">
                <Input v-model="formValidate.content1" maxlength="200" show-word-limit type="textarea"
                       placeholder="请输入作业内容" v-if="item==1"/>
                <Input v-model="formValidate.content2" maxlength="200" show-word-limit type="textarea"
                       placeholder="请输入作业内容" v-if="item==2"/>
                <Input v-model="formValidate.content3" maxlength="200" show-word-limit type="textarea"
                       placeholder="请输入作业内容" v-if="item==3"/>
                <Input v-model="formValidate.content4" maxlength="200" show-word-limit type="textarea"
                       placeholder="请输入作业内容" v-if="item==4"/>
                <Input v-model="formValidate.content5" maxlength="200" show-word-limit type="textarea"
                       placeholder="请输入作业内容" v-if="item==5"/>
                <Input v-model="formValidate.content6" maxlength="200" show-word-limit type="textarea"
                       placeholder="请输入作业内容" v-if="item==6"/>
                <Input v-model="formValidate.content7" maxlength="200" show-word-limit type="textarea"
                       placeholder="请输入作业内容" v-if="item==7"/>
              </FormItem>
            </TabPane>
          </Tabs>
          <FormItem label="发布时间" prop="pushTime" v-if="formValidate.repeatStatus">
            <TimePicker format="HH:mm" v-model="formValidate.pushTime" placeholder="发布时间"></TimePicker>
          </FormItem>
          <FormItem label="发布对象" prop="pushType">
            <RadioGroup v-model="formValidate.pushType">
              <Radio :label="1">班级</Radio>
              <!--              <Radio :label="2">学生</Radio>-->
            </RadioGroup>
          </FormItem>
          <!--          {{ formValidate }}-->
          <Spin size="large" fix v-if="loading"></Spin>
          <FormItem label="选择" prop="classIds" v-if="formValidate.pushType==1">
            <CheckboxGroup v-model="formValidate.classIds">
              <Checkbox :label="item.classInfo.classId" v-for="(item,index) in classOfStudentList" :key="index">
                {{ item.classInfo.className }}
              </Checkbox>
            </CheckboxGroup>
          </FormItem>
          <FormItem label="选择" prop="students" v-if="formValidate.pushType==2">
            <div style="display: flex;flex-direction: column">
              <div v-for="(item,index) in classOfStudentList" :key="index"
                   style="border-bottom: 1px solid #e9e9e9;padding-bottom:6px;margin-bottom:6px;">
                <div>
                  <Checkbox :value="item.checkAll"
                            :indeterminate="item.indeterminate"
                            @click.prevent.native="handleCheckAll(index)">
                    {{ item.classInfo.className }}
                  </Checkbox>
                </div>
                <div>
                  <CheckboxGroup v-model="formValidate.students" @on-change="checkAllGroupChange(index)">
                    <Checkbox :label="item2.userSysNo" v-for="(item2,index2) in item.students" :key="index2">
                      {{ item2.userName }}
                    </Checkbox>
                  </CheckboxGroup>
                </div>
              </div>
            </div>
          </FormItem>
          <FormItem>
            <Button type="primary" @click="handleSubmit('formValidate')">发布</Button>
            <Button @click="closeModal" style="margin-left: 8px">取消</Button>
          </FormItem>
        </Form>
      </div>
    </Modal>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      queryForm: {
        time: []
      },
      modalStatus: false,
      tableLoading: true,
      loading: false,
      pageList: [],
      pageData: {
        pageNo: 1,
        pageSize: 10,
        total: 0
      },
      classOfStudentList: [],
      formValidate: {
        name: '',
        content1: '',
        content2: '',
        content3: '',
        content4: '',
        content5: '',
        content6: '',
        content7: '',
        repeatTime: [],
        pushTime: '',
        workType: 1,
        pushType: 1,
        classIds: [],
        students: [],
        planTime: [],
        repeatStatus: false
      },
      ruleValidate: {
        name: [
          {required: true, message: '请输入作业名称', trigger: 'blur'}
        ],
        content1: [
          {required: true, message: '请输入作业内容', trigger: 'blur'}
        ],
        content2: [
          {required: true, message: '请输入作业内容', trigger: 'blur'}
        ],
        content3: [
          {required: true, message: '请输入作业内容', trigger: 'blur'}
        ],
        content4: [
          {required: true, message: '请输入作业内容', trigger: 'blur'}
        ],
        content5: [
          {required: true, message: '请输入作业内容', trigger: 'blur'}
        ],
        content6: [
          {required: true, message: '请输入作业内容', trigger: 'blur'}
        ],
        content7: [
          {required: true, message: '请输入作业内容', trigger: 'blur'}
        ],
        repeatTime: [
          {required: true, type: 'array', message: '请选择重复时间', trigger: 'change'}
        ],
        pushTime: [
          {required: true, type: 'string', message: '请选择发布时间', trigger: 'change'}
        ],
        workType: [
          {required: true, message: '请选择作业类型', trigger: 'change', type: 'number'}
        ],
        pushType: [
          {required: true, message: '请选择发布对象', trigger: 'change', type: 'number'}
        ],
        classIds: [
          {required: true, type: 'array', min: 1, message: '请选择班级', trigger: 'change'},
        ],
        students: [
          {required: true, type: 'array', min: 1, message: '请选择学生', trigger: 'change'},
        ],
        planTime: [
          {
            required: true, type: 'array', fields: {
              0: {required: true, message: '请选择起止时间', trigger: 'change'},
              1: {required: true, message: '请选择起止时间', trigger: 'change'}
            },
            message: '请选择起止时间',
            trigger: 'change'
          }
        ],
      },
      repeatTimeList: [
        {
          id: 1,
          name: '每周一'
        },
        {
          id: 2,
          name: '每周二'
        },
        {
          id: 3,
          name: '每周三'
        },
        {
          id: 4,
          name: '每周四'
        },
        {
          id: 5,
          name: '每周五'
        },
        {
          id: 6,
          name: '每周六'
        },
        {
          id: 7,
          name: '每周日'
        },
      ],
      columns: [
        {
          title: '提交时间',
          key: 'created_at'
        },
        {
          title: '操作',
          slot: 'action',
          fixed: 'right',
          width: 100,
        }
      ]
    }
  },
  mounted() {
  },
  methods: {
    changePageNo(val) {
      this.pageData.pageNo = val;
      this.queryPageList();
    },
    showModal() {
      this.modalStatus = true;
      this.queryClassStudent();
    },
    closeModal() {
      this.modalStatus = false;
      this.formValidate = {
        name: '',
        content1: '',
        content2: '',
        content3: '',
        content4: '',
        content5: '',
        content6: '',
        content7: '',
        repeatTime: [],
        pushTime: '',
        workType: 1,
        pushType: 1,
        classIds: [],
        students: [],
        planTime: [],
        repeatStatus: false
      }
    },
    queryClassStudent() {
      this.loading = true
      this.$http.queryClassStudent().then(res => {
        this.loading = false
        if (res.status == 1) {
          this.classOfStudentList = res.data.map(item => {
            item.indeterminate = false;
            item.checkAll = false;
            return item;
          });
        }
      })
    },
    handleCheckAll(index) {
      if (this.classOfStudentList[index].indeterminate) {
        this.classOfStudentList[index].checkAll = false;
      } else {
        this.classOfStudentList[index].checkAll = !this.classOfStudentList[index].checkAll;
      }
      this.classOfStudentList[index].indeterminate = false;

      if (this.classOfStudentList[index].checkAll) {
        for (let student of this.classOfStudentList[index].students) {
          if (this.formValidate.students.indexOf(student.userSysNo) == -1) {
            this.formValidate.students.push(student.userSysNo);
          }
        }
      } else {
        for (let student of this.classOfStudentList[index].students) {
          let delIndex = this.formValidate.students.indexOf(student.userSysNo);
          if (delIndex != -1) {
            this.formValidate.students.splice(delIndex, 1);
          }
        }
      }
    },
    checkAllGroupChange(index) {
      let cnt = 0;
      for (let student of this.classOfStudentList[index].students) {
        if (this.formValidate.students.indexOf(student.userSysNo) != -1) {
          cnt++;
        }
      }
      if (cnt == this.classOfStudentList[index].students.length) {
        this.classOfStudentList[index].indeterminate = false;
        this.classOfStudentList[index].checkAll = true;
      } else if (cnt > 0) {
        this.classOfStudentList[index].indeterminate = true;
        this.classOfStudentList[index].checkAll = false;
      } else {
        this.classOfStudentList[index].indeterminate = false;
        this.classOfStudentList[index].checkAll = false;
      }
    },
    handleSubmit(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          if (this.formValidate.workType == 2) {
            let students = [];
            if (this.formValidate.pushType == 2) {//1班级，2学生
              for (let ele of this.formValidate.students) {
                for (let item of this.classOfStudentList) {
                  for (let student of item.students) {
                    if (student.userSysNo == ele) {
                      students.push(student)
                    }
                  }
                }
              }
            }
            this.$downloadDoc('/teacher/pushPdfHomework', this.formValidate.pushType, this.formValidate.classIds, students);
            this.closeModal();
          } else {
            this.pushHomeOnline();
          }
        }
      })
    },
    pushHomeOnline() {
      let students = [];
      if (this.formValidate.pushType == 2) {//1班级，2学生
        for (let ele of this.formValidate.students) {
          for (let item of this.classOfStudentList) {
            for (let student of item.students) {
              if (student.userSysNo == ele) {
                students.push(student)
              }
            }
          }
        }
      }
      let data = {
        name: this.formValidate.name,
        content: this.formValidate.content,
        pushType: this.formValidate.pushType,
        classIds: this.formValidate.classIds,
        students: students,
        repeatStatus: this.formValidate.repeatStatus == true ? 2 : 1
      };
      if (this.formValidate.repeatStatus == true) {
        data.contentArray = [];
        for (let item of this.formValidate.repeatTime) {
          if (item == 1) {
            data.contentArray.push(this.formValidate.content1);
          } else if (item == 2) {
            data.contentArray.push(this.formValidate.content2);
          } else if (item == 3) {
            data.contentArray.push(this.formValidate.content3);
          } else if (item == 4) {
            data.contentArray.push(this.formValidate.content4);
          } else if (item == 5) {
            data.contentArray.push(this.formValidate.content5);
          } else if (item == 6) {
            data.contentArray.push(this.formValidate.content6);
          } else if (item == 7) {
            data.contentArray.push(this.formValidate.content7);
          }
        }
        data.repeatTime = this.formValidate.repeatTime.join();
        data.pushTime = this.formValidate.pushTime;
        data.startTime = this.formValidate.planTime[0];
        data.endTime = this.formValidate.planTime[1];
      }
      // console.log(data);
      this.$http.pushHomeOnline(data).then(res => {
        if (res.status == 1) {
          this.closeModal();
          this.$emit('ok');
          this.$Message.success('发布成功！');
        }
      });
    },
    setPlanTime(data) {
      if (data.length > 1) {
        this.formValidate.planTime = data;
      }
    },
  }
}
</script>

<style scoped lang="less">

</style>